import throttle from 'lodash/throttle';
import { useCallback, useLayoutEffect, useState, useRef } from 'react';
import ResizeObserver from 'resize-observer-polyfill';
function useElementRect(ref) {
    const observerRef = useRef();
    const [rect, setRect] = useState(); // Cache callback.
    const callback = useCallback(throttle(() => {
        if (!ref.current) {
            return;
        }
        setRect(ref.current.getBoundingClientRect());
    }, 100), [ref.current]);
    useLayoutEffect(() => {
        // Ensure element ref has been supplied.
        if (!ref.current) {
            return;
        }
        if (!observerRef.current) {
            observerRef.current = new ResizeObserver(callback);
            observerRef.current.observe(ref.current);
        } // Set initial value.
        setRect(ref.current.getBoundingClientRect()); // Ensure callback is removed when unmounted.
        return () => {
            if (observerRef.current) {
                observerRef.current.disconnect();
            }
        };
    }, []);
    return rect;
}
export default useElementRect;
